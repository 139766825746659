:root {
  /* General */
  --font-family: Inter;
  --font-family-header: Inter;
  --default-font-size: 14px;
  --action-button-size: 36px;
  /* General - END */

  /* Color */
  --brand-base-colour: #C4C4C4;
  --brand-black: #000;
  --brand-black-hover: #121214;
  --brand-grey: rgba(0, 0, 0, 0.16);
  --brand-grey-hover: rgba(0, 0, 0, 0.24);
  --brand-brown: #bb7f3e;
  --brand-brown-hover: #705233;
  --brand-light-brown: rgba(187, 127, 62, 0.16);
  --brand-light-brown-hover: rgba(187, 127, 62, 0.24);
  --danger: #fd3d50;
  --danger-hover: #e33a4b;
  --danger-light: rgba(253, 61, 80, 0.16);
  --danger-light-hover: rgba(253, 61, 80, 0.24);
  --warning: #fdb417;
  --warning-hover: #e5ac4a;
  --warning-light: rgba(255, 190, 79, 0.16);
  --warning-light-hover: rgba(255, 190, 79, 0.24);
  --success: #80c342;
  --success-hover: #75b03f;
  --success-light: rgba(61, 204, 121, 0.16);
  --success-light-hover: rgba(61, 204, 121, 0.24);
  --gray: #3e4b57;
  --gray-1: #617283;
  --gray-2: #617283;
  --gray-3: #a5adba;
  --gray-4: #d2d6dd;
  --gray-5: #f7f8f8;
  --black: #272729;
  --white: #fff;
  --gray-text: #959595;
  --gray-search-text: #e6e6eb;
  --def-colour:#06bab3;
  --def-colour-price:#002f54;
  --border:#979797;
  --qty-background: #D8D8D8;
  --border-txt: #f3f3f3;
  --box-shadow: rgba(141, 141, 148, 0.16);
  --box-shadow-1: rgba(141, 141, 148, 0.48);
  --boarder-colour : #76787a;
  --boarder-shadow :rgba(0, 0, 0, 0.25);
  --border-colour-2 :#D0D5DD;
  --box-shadow-3:rgba(16, 24, 40, 0.10);
  --light-red: #FEF3F2;
  --red-border: #FECDCA;
  --red:#B42318;
  --yellow: #FDB022;
  --dark-gray: #667085;
  --gray-border: #EAECF0;
  --light-gray: #F9FAFB;
  --light-gray2: #D0D5DD;
  --text-color: #475467;
  --border-button: #7F56D9;
  --border-dark :344054;
  --border-gray :rgba(196, 196, 196, 0.44);
  --background-3 :#F5F5F5;
  --zone : #617036;
  --zone-2 : rgba(9 33 33 / 88%);
  --hr:#e4e7ec;
  --back:rgba(187, 127, 62, 0.5);
  --back2:#131313;
  --hover-hestory:#efe0d1;
  --bottom-c : #ddd;
  --background-shadow:rgba(0, 0, 0, 0.82);
  --border-bottom: rgba(255, 255, 255, 0.3);
  --white-bg : #828894;
  --border-left: rgba(141, 141, 148, 0.48);
  /* Color - END */

  /*new colour*/
  --colors-text-text-primary-900 :#101828;
  /*new colour --END*/

  /* Layout */
  --header-height: 60px;
  --header-brand-width: 236px;
  --navbar-height: var(--header-height);
  --footer-info-height: 280px;
  --footer-rights-height: var(--header-height);
  --max-modal-height: 80vh;
  /* Layout - END */

  /* Spacing */
  --spacing-none: 0px;
  --spacing-xxs: 2px;
  --spacing-xs: 4px;
  --spacing-sm: 6px;
  --spacing-md: 8px;
  --spacing-lg: 12px;
  --spacing-xl: 16px;
  --spacing-2xl: 20px;
  --spacing-3xl: 24px;
  --spacing-4xl: 32px;
  --spacing-5xl: 40px;
  --spacing-6xl: 48px;
  --spacing-7xl: 64px;
  --spacing-8xl: 80px;
  --spacing-9xl: 96px;
  --spacing-10xl: 128px;
  --spacing-11xl: 160px;
  /* Spacing - END */

  /* negative-Spacing */
  --neg-spacing-xxs: -2px;
  --neg-spacing-xs: -4px;
  --neg-spacing-sm: -6px;
  --neg-spacing-md: -8px;
  --neg-spacing-lg: -12px;
  --neg-spacing-xl: -16px;
  --neg-spacing-2xl: -20px;
  --neg-spacing-3xl: -24px;
  --neg-spacing-4xl: -32px;
  --neg-spacing-5xl: -40px;
  --neg-spacing-6xl: -48px;
  --neg-spacing-7xl: -64px;
  --neg-spacing-8xl: -80px;
  --neg-spacing-9xl: -96px;
  --neg-spacing-10xl: -128px;
  --neg-spacing-11xl: -160px;
  /* negative-Spacing  - END */

  /* Width */
  --width-xxs:320px;
  --width-xs: 384px;
  --width-sm: 480px;
  --width-md: 560px;
  --width-lg: 640px;
  --width-xl: 768px;
  --width-2xl: 1,024px;
  --width-3xl: 1,280px;
  --width-4xl: 1,440px;
  --width-5xl: 1,600px;
  --width-6xl: 1,920px;
  /* Width - END */

  /*Radius*/
  --radius-none: 0px;
  --radius-xxs: 2px;
  --radius-xs: 4px;
  --radius-sm: 6px;
  --radius-md: 8px;
  --radius-lg: 10px;
  --radius-xl: 12px;
  --radius-2xl: 16px;
  --radius-3xl: 20px;
  --radius-4xl: 24px;
  --radius-full: 9999px;
  /*Radius -END*/

}

